import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap"

const ShowcaseCards = data => {
  //console.log(data.type)

  // Gracefully handle unknown types
  let type = data.type
  // try { type = data.props.relationships.field_content_type.info } catch (e) {}
  // console.log(type)

  let entries
  switch (type) {
    case "Team Member (Cards)":
      entries = data.data.allNodeTeamMember.nodes
      return <TeamMember data={entries} icon={data.data.linkedin} />
    default:
      return <p>[No data found]</p>
  }
}
const TeamMember = props => {
  return (
    <Container style={{ paddingTop: "10px" }}>
      <Row>
        {props.data.map((entry, key) => {
          // console.log(entry.node)
          return (
            <Col
              xs={12}
              md={6}
              xl={4}
              className={"text-left"}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                // paddingTop: "15px",
                paddingBottom: "20px",
              }}
              key={key}
            >
              <div
                style={{
                  backgroundColor: "#ffffff",
                  boxShadow: "rgba(0, 0, 0, .5) 5px 5px 15px",
                  height: "100%",
                }}
              >
                <Col
                  xs={12}
                  style={{
                    paddingTop: "300px",
                    paddingBottom: "30px",
                  }}
                >
                  {
                    // Background Image
                    entry.relationships.field_team_image && (
                      <GatsbyImage
                        image={entry.relationships.field_team_image.relationships
                          .field_media_image.localFile.childImageSharp.gatsbyImageData}
                        imgStyle={{ objectFit: "cover" }}
                        alt={
                          entry.relationships.field_team_image.field_media_image
                            .alt
                        }
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                        }} />
                    )
                  }
                </Col>
                <Col
                  xs={12}
                  style={{
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <h4
                    style={{
                      padding: 0,
                      marginTop: "0.25em",
                      marginBottom: "0.25em",
                    }}
                  >
                    {entry.title}
                  </h4>
                  <h5>{entry.field_job_title}</h5>
                  <a
                    href={entry.field_linkedin}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={props.icon.publicURL}
                      alt=""
                      title=""
                      style={{ height: "40px" }}
                    />
                  </a>
                </Col>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
export default props => (
  <StaticQuery
    query={graphql`{
  linkedin: file(relativePath: {eq: "social-linkedin.svg"}) {
    publicURL
  }
  allNodeTeamMember(sort: {fields: created, order: ASC}) {
    nodes {
      id
      created
      title
      path {
        alias
      }
      field_job_title
      field_linkedin
      relationships {
        field_team_image {
          field_media_image {
            alt
          }
          relationships {
            field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}
`}
    render={data => <ShowcaseCards data={data} {...props} />}
  />
)
