import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import classNames from "classnames"

import { hexaToDark, hexaToRgba } from "../functions"
import Bricks from "./bricks"

export default props => {
  let { className, backgroundColor, backgroundMedia, ...rest } = props

  return (
    <section
      {...rest}
      className={classNames(className, {
        dark: hasDarkClass(props),
      })}
      children={<Children props={props} />}
    />
  )
}

const Children = ({ props }) => {
  let { bricks } = props
  return (
    <>
      {props.backgroundMedia && <BackgroundMedia props={props} />}
      {props.backgroundColor && <BackgroundColor props={props} />}
      {bricks ? <Bricks bricks={props.children} /> : props.children}
    </>
  )
}

const BackgroundMedia = ({ props }) => {
  if (props.backgroundMedia.format === "image") {
    return (
      <GatsbyImage
        imgStyle={{ objectFit: "cover" }}
        image={
          props.backgroundMedia.data.relationships.field_media_image.localFile
            .childImageSharp.gatsbyImageData
        }
        // sizes={{
        //   ...props.backgroundMedia.data.relationships.field_media_image
        //     .localFile.childImageSharp.gatsbyImageData,
        // }}
        className={`background-cover`}
      />
    )
  } else return null
}

const BackgroundColor = ({ props }) => {
  if (props.backgroundColor.format === "color") {
    return (
      <div
        className={`background-cover`}
        style={{
          backgroundColor: `${hexaToRgba(props.backgroundColor.data)}`,
        }}
      />
    )
  } else if (props.backgroundColor.format === "gradient") {
    return null
  } else return null
}

const hasDarkClass = props => {
  let rtn = false
  if (props.backgroundColor && hexaToDark(props.backgroundColor.data))
    rtn = true
  return rtn
}
