import React from "react"
import Bricks from "./bricks"
import { Container as BootstrapContainer, Row } from "react-bootstrap"

class Container extends React.Component {
  render() {
    let { row_justification, ...rest } = this.props
    return (
      <BootstrapContainer
        {...rest}
        children={<Children props={this.props} />}
        style={{ position: "relative" }}
      />
    )
  }
}
export default Container

const Children = ({ props }) => {
  return (
    <Row className={props.row_justification}>
      <Bricks bricks={props.children} />
    </Row>
  )
}
