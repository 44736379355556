import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"

import classNames from "classnames"
import Buttons from "../../components/paragraph/buttons"
import Html from "../../components/paragraph/html"

export default class PageHeader extends Component {
  render() {
    switch (this.props.data.type) {
      case "eck_page_header__image":
        return <HeaderImage data={this.props.data} />
      default:
        return <HeaderText data={this.props.data} />
    }
  }
}

class HeaderText extends Component {
  render() {
    return (
      <section className={`c_PageHeader__text dark`}>
        <Container>
          <Row>
            <Col>
              <h1 className={`text-center`}>{this.props.data.title}</h1>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

class HeaderImage extends Component {
  render() {
    let homePage = this.props.data.homePage
    let header = this.props.data.data.relationships
    let caption = header.field_caption
    //console.log(image)
    return (
      <Container
        className={classNames(`d-flex c_PageHeader`, {
          c_PageHeader_fullscreen: homePage,
        })}
        fluid={true}
        // style={{
        //   minHeight: "calc(100vh - 110px)",
        //   position: "relative",
        //   overflow: "hidden",
        // }}
      >
        {/* Landscape Image */}
        {header.field_image && (
          <GatsbyImage
            image={
              header.field_image.relationships.field_media_image.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
            style={{
              position: "absolute",
              minWidth: "100%",
              minHeight: "100%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
        {/* Content */}
        <Container className={"m-auto"}>
          <Row className={"text-align"}>
            <Col xs={12} md={10} xl={7}>
              {caption.map((caption, index) => {
                let type
                try {
                  type = caption.internal.type
                } catch (e) {}
                switch (type) {
                  case "paragraph__component_html":
                    return <Html props={caption} key={index} />
                  case "paragraph__component_button_s":
                    return <Buttons props={caption} key={index} />
                  default:
                    return (
                      <p
                        key={index}
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          background: "white",
                        }}
                      >
                        Failed to load component!
                      </p>
                    )
                }
              })}
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}
