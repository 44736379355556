import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GoChevronRight, GoLinkExternal } from "react-icons/go"

const btnClasses = "btn btn-primary btn-md"

export default ({ props }) => {
  return props.relationships.field_button_s.map((button, index) => {
    // console.log(button)

    const title = button.field_link.title
    const uri = button.field_link.uri
    const uriTokens = uri.split(":")

    switch (uriTokens[0]) {
      case "internal":
        return (
          <Internal
            key={index}
            href={uriTokens[1]}
            title={title}
            className={btnClasses}
          />
        )
      //  Invalid link since the Drupal link widget won't convert entity/node/x into alias
      case "entity":
        return (
          <Link
            key={index}
            to={"/"}
            children={"Invalid Link"}
            className={btnClasses}
          />
        )
      default:
        return (
          <External
            key={index}
            href={uri}
            title={title}
            className={btnClasses}
          />
        )
    }
  })
}

const Internal = props => {
  let { href, title } = props

  if (href.substr(0, 1) === "#")
    return (
      <AnchorLink
        {...props}
        to={
          (typeof window !== "undefined" ? window.location.pathname : "") + href
        }
      >
        {title} <GoChevronRight />
      </AnchorLink>
    )
  else
    return (
      <Link {...props} to={href}>
        {title} <GoChevronRight />
      </Link>
    )
}

const External = props => {
  let { href, title } = props
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
      {title} <GoLinkExternal />
    </a>
  )
}
