import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { Col, Container, Row } from "react-bootstrap"

import "./layoutFooter.scss"

const Footer = props => {
  // console.log(props)

  const data = useStaticQuery(
    graphql`
      query {
        allMenuFooter {
          edges {
            node {
              title
              relative
              alias
            }
          }
        }
        logoPNG: file(relativePath: { eq: "anatas-color-logo1.jpg" }) {
          publicURL
        }
        atturaLogo: file(relativePath: { eq: "atturra-logo-white.png" }) {
          publicURL
        }
      }
    `
  )

  return (
    <footer className={"c-footer"}>
      <section>
        <Container>
          <Row className={"justify-content-center"}>
            <Col xs={12} md={10} xl={8}>
              <Row className={"justify-content-center"}>
                {data.allMenuFooter &&
                  data.allMenuFooter.edges.map((edge, index) => {
                    return (
                      <div key={index} className={"footer-link"}>
                        <Link to={edge.node.relative}>{edge.node.title}</Link>
                      </div>
                    )
                  })}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className={"container--top-border"}>
          <Row className={"justify-content-center"}>
            <Col xs={12} md={6} xl={4} className={"text-center"}>
              <p>
                <a href="mailto:info@anatas.com.au" style={{ fontSize: 18 }}>
                  info@anatas.com.au
                </a>
              </p>
              <p>
                <a href="tel:+61296570999" style={{ fontSize: 18 }}>
                  +61 2 9657 0999
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section style={{ background: "white", padding: "15px 0" }}>
        <Container>
          <Row>
            <Col className={"text-center"}>
              <img
                src={data.logoPNG.publicURL}
                alt={"Twitter"}
                style={{ height: "50px", margin: "10px" }}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className={"section dark"}
        style={{
          position: "relative",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(30, 23, 84)",
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
          }}
        ></div>
        <Container fluid={true}>
          <Row className={"justify-content-between"}>
            <div
              // xs={8}
              className={"d-flex text-left"}
              style={{ padding: "0 15px" }}
            >
              <p className={"mt-auto mb-auto"} style={{ fontSize: "1rem" }}>
                <span style={{ paddingRight: "15px" }}>
                  <a href="/sitemap.xml">Sitemap</a>
                </span>
                <span style={{ paddingRight: "15px" }}>
                  <a href="/privacy-policy">Privacy policy</a>
                </span>
                <span style={{ paddingRight: "0" }}>
                  © {props.siteTitle} {new Date().getFullYear()}
                </span>
              </p>
            </div>
            <div
              // xs={4}
              className={"d-flex justify-content-end"}
              style={{ padding: "0 15px" }}
            >
              <a
                href="https://atturra.com/au-en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={"Atturra Logo"}
                  src={data.atturaLogo.publicURL}
                  style={{ height: "21px", margin: 0 }}
                />
              </a>
            </div>
          </Row>
        </Container>
      </section>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
