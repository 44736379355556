import React from "react"
import { Col } from "react-bootstrap"

import Bricks from "./bricks"
import Buttons from "../paragraph/buttons"
import ShowcaseCards from "../paragraph/showcase_cards"
import CarouselRibbon from "../paragraph/carousel_ribbon"
import Html from "../paragraph/html"
import Heading from "../paragraph/heading"
import Image from "../paragraph/image"
import Form from "../paragraph/form"

class Column extends React.Component {
  render() {
    let { components, ...rest } = this.props
    return (
      <Col
        {...rest}
        children={<Children props={this.props} />}
        // style={{ position: "relative" }}
      />
    )
  }
}

export default Column

const Children = ({ props }) => {
  return (
    <>
      {props.components.map((p, index) => {
        // Gracefully handle unknown types
        let type
        try {
          type = p.internal.type
        } catch (e) {}

        switch (type) {
          case "paragraph__component_button_s":
            return <Buttons props={p} key={index} />
          case "paragraph__component_heading":
            return <Heading props={p} key={index} />
          case "paragraph__component_html":
            return <Html props={p} key={index} />
          case "paragraph__component_form":
            return <Form props={p} key={index} />
          case "paragraph__component_image":
            return <Image props={p} key={index} />
          case "paragraph__component_showcase_cards":
            return (
              <ShowcaseCards
                key={index}
                type={p.relationships.field_content_type.info}
              />
            )
          case "paragraph__component_carousel_ribbon":
            return (
              <CarouselRibbon
                type={p.relationships.field_content_type.info}
                key={index}
              />
            )
          default:
            return (
              <p
                key={index}
                style={{
                  color: "red",
                  fontWeight: "bold",
                  background: "white",
                }}
              >
                Failed to load component!
              </p>
            )
        }
      })}
      <Bricks bricks={props.children} />
    </>
  )
}
