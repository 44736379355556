import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import SEO from "../../components/seo"
import Layout from "../layout"
import Header from "./header"
import Bricks from "../../components/structural/bricks"
import "./page.scss"

const PageTemplate = ({ data }) => {
  const node = data.allNodePage.edges[0].node
  const title = data.allNodePage.edges[0].node.title

  // shareImage is passed to SEO component as OG:IMAGE metadata
  let shareImage = undefined
  try {
    shareImage = getSrc(
      data.allNodePage.edges[0].node.relationships.field_page_header[0]
        .relationships.field_image.relationships.field_media_image.localFile
    )
    // shareImage =
    //   data.allNodePage.edges[0].node.relationships.field_page_header[0]
    //     .relationships.field_image.relationships.field_media_image.localFile
    //     .childImageSharp.gatsbyImageData.src
  } catch {
    shareImage = false
  }

  const eck_page_header = {
    type: node.relationships.field_page_header[0]
      ? node.relationships.field_page_header[0].internal.type
      : null,
    data: node.relationships.field_page_header[0],
    title: node.title,
    homePage: true
      ? node.drupal_id === "369d9eca-e79c-4c58-b762-23b3b213319b"
      : false,
  }

  const eck_page_body = {
    depths: node.field_page_body,
    bricks: node.relationships.field_page_body,
  }

  // Handle Bricks formatting and mapping //
  const merge = (a, b) => {
    let rtn = []
    for (var i = 0; i < a.length; i++) {
      rtn.push({
        depth: a[i].depth,
        brick: b[i],
      })
    }
    return rtn
  }
  var input = merge(eck_page_body.depths, eck_page_body.bricks)
  var bricks = []
  var parents = [bricks]
  for (var el of input) {
    let nv = {
      type: el.brick.internal.type,
      id: el.brick.drupal_id,
      children: [],
    }
    parents[el.depth].push(nv)
    parents[++el.depth] = nv.children
  }
  // \END Handle bricks formatting and mapping //

  return (
    <Layout title={node.title}>
      <SEO title={title} image={shareImage} />
      <Header data={eck_page_header} />
      <Bricks bricks={bricks} />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`query ($id: String!) {
  allNodePage(filter: {id: {eq: $id}}) {
    edges {
      node {
        id
        drupal_id
        title
        field_page_body {
          depth
        }
        relationships {
          field_page_header {
            ... on eck_page_header__image {
              internal {
                type
              }
              relationships {
                field_image {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                      }
                    }
                  }
                }
                field_caption {
                  ... on paragraph__component_html {
                    internal {
                      type
                    }
                    field_html {
                      processed
                    }
                  }
                  ... on paragraph__component_button_s {
                    internal {
                      type
                    }
                    relationships {
                      field_button_s {
                        field_link {
                          title
                          uri
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          field_page_body {
            ... on eck_page_body__section {
              drupal_id
              internal {
                type
              }
            }
            ... on eck_page_body__container {
              drupal_id
              internal {
                type
              }
            }
            ... on eck_page_body__column {
              drupal_id
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
}
`
