import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Section from "./section"
import Container from "./container"
import Column from "./column"

const Bricks = ({ bricks }) => {
  const query = useStaticQuery(graphql`{
  allEckPageBodySection {
    edges {
      node {
        drupal_id
        field_background_color {
          color
          opacity
        }
        relationships {
          field_background_image {
            relationships {
              field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  allEckPageBodyContainer {
    edges {
      node {
        id
        drupal_id
        field_row_justification
        field_fluid
        field_classes
      }
    }
  }
  allEckPageBodyColumn {
    edges {
      node {
        drupal_id
        field_classes
        relationships {
          field_column_spans {
            field_span_md
            field_span_xl
            field_span_xs
          }
          field_components {
            ... on paragraph__component_button_s {
              internal {
                type
              }
              relationships {
                field_button_s {
                  field_link {
                    title
                    uri
                  }
                }
              }
            }
            ... on paragraph__component_html {
              internal {
                type
              }
              field_html {
                processed
              }
            }
            ... on paragraph__component_heading {
              internal {
                type
              }
              field_heading
              field_heading_type
            }
            ... on paragraph__component_showcase_cards {
              internal {
                type
              }
              id
              relationships {
                field_content_type {
                  info
                  relationships {
                    paragraph__component_showcase_cards {
                      id
                    }
                  }
                }
              }
            }
            ... on paragraph__component_form {
              internal {
                type
              }
              field_form_id
              field_form_id_2
            }
            ... on paragraph__component_image {
              internal {
                type
              }
              field_fluid
              relationships {
                field_image {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                        publicURL
                      }
                    }
                  }
                }
              }
            }
            ... on paragraph__component_carousel_ribbon {
              id
              internal {
                type
              }
              relationships {
                field_content_type {
                  info
                  relationships {
                    paragraph__component_carousel_ribbon {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`)

  if (bricks === undefined || bricks.length === 0) return null

  return bricks.map((brick, index) => {
    //////////////////////////////////////////////////
    // TYPE: Section
    if (brick.type === "eck_page_body__section") {
      const section = findBrick(query.allEckPageBodySection.edges, brick.id)

      // Define Props

      let id
      let backgroundColor
      let backgroundMedia

      id = "ID_" + brick.id

      if (section.relationships.field_background_gradient) {
        backgroundColor = {
          format: "gradient",
          data: section.relationships.field_background_gradient.name,
        }
      } else if (section.field_background_color) {
        backgroundColor = {
          format: "color",
          data: section.field_background_color,
        }
      } else {
        backgroundColor = undefined
      }

      if (section.relationships.field_background_image) {
        backgroundMedia = {
          format: "image",
          data: section.relationships.field_background_image,
        }
      } else {
        backgroundMedia = undefined
      }

      return (
        <Section
          key={index}
          id={id}
          backgroundColor={backgroundColor}
          backgroundMedia={backgroundMedia}
          children={brick.children}
          bricks={1}
        />
      )
    }
    //////////////////////////////////////////////////
    // TYPE: Container
    else if (brick.type === "eck_page_body__container") {
      const container = findBrick(query.allEckPageBodyContainer.edges, brick.id)
      return (
        <Container
          id={`ID_${brick.id}`}
          className={container.field_classes}
          fluid={container.field_fluid}
          row_justification={container.field_row_justification}
          children={brick.children}
          key={index}
        />
      )
    }
    //////////////////////////////////////////////////
    // TYPE: Column
    else if (brick.type === "eck_page_body__column") {
      let column = query.allEckPageBodyColumn.edges.find(edge => {
        return edge.node.drupal_id === brick.id ? true : false
      }).node
      return (
        <Column
          xs={
            column.relationships.field_column_spans &&
            column.relationships.field_column_spans.field_span_xs
          }
          md={
            column.relationships.field_column_spans &&
            column.relationships.field_column_spans.field_span_md
          }
          xl={
            column.relationships.field_column_spans &&
            column.relationships.field_column_spans.field_span_xl
          }
          className={column.field_classes}
          components={column.relationships.field_components}
          children={brick.children}
          key={index}
        />
      )
    } else
      return (
        <p key={index} className={`text-danger`}>
          Requested content not known (generated by Bricks.js)
        </p>
      )
  })

  // return <p>bricks</p>
}

export default Bricks

function findBrick(edges, id) {
  return edges.find(edge => {
    return edge.node.drupal_id === id ? true : false
  }).node
}
