export function hexaToRgba(hexa) {
  let r = parseInt("0x" + hexa.color.substring(0, 2))
  let g = parseInt("0x" + hexa.color.substring(2, 4))
  let b = parseInt("0x" + hexa.color.substring(4, 6))
  let a = hexa.opacity
  return "rgba(" + r + "," + g + "," + b + "," + a + ")"
}

export function hexaToDark(hexa) {
  let r = parseInt("0x" + hexa.color.substring(0, 2))
  let g = parseInt("0x" + hexa.color.substring(2, 4))
  let b = parseInt("0x" + hexa.color.substring(4, 6))
  return r + g + b < 550 ? true : false
}