import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { GoChevronRight } from "react-icons/go"
import { Col, Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap"

import "./layoutHeader.scss"

const LayoutHeader = props => {
  const data = useStaticQuery(
    graphql`
      query {
        logoPNG: file(relativePath: { eq: "anatas-color-logo1.jpg" }) {
          publicURL
        }
        atturaLogo: file(relativePath: { eq: "atturra-logo-white.png" }) {
          publicURL
        }
        allMenuMain {
          edges {
            node {
              title
              relative
              below {
                title
                relative
              }
            }
          }
        }
      }
    `
  )

  const [dropdownOpen, setDropdownOpen] = React.useState(undefined)

  let onMouseEnter = index => {
    setDropdownOpen(index)
  }

  let onMouseLeave = () => {
    setDropdownOpen(undefined)
  }

  return (
    <header
      style={{
        borderBottom: "solid 4px #3452A4",
        position: "relative",
        // top: 0,
        // width: "100%",
        zIndex: 9998,
      }}
    >
      {/* Ticker Banner */}
      <Container
        fluid={true}
        style={{ backgroundColor: "rgb(52, 82, 164)", padding: "10px 15px" }}
      >
        <Row className="banner-top">
          <div
            className="d-flex text-left"
            style={{
              padding: "0px 15px",
              top: "20px",
              position: "absolute",
            }}
          >
            <img
              className="banner-image"
              src={data.atturaLogo.publicURL}
              alt="Atturra"
            />
          </div>

          <div
            className="d-flex text-center banner-copy"
            style={{
              width: "100%",
              marginTop: "12px",
              padding: "0px 15px",
            }}
          >
            <p
              style={{ fontSize: "18px", color: "#ffffff" }}
              className="m-auto"
            >
              <strong>
                ANATAS is now the Data & Integration arm of Atturra, formerly
                FTS Group.
              </strong>

              <a
                style={{
                  margin: "0.5rem",
                  textTransform: "initial",
                }}
                href="https://atturra.com/au-en/press-room/fts-group-consolidation-rebrand-atturra"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-custom btn-banner"
              >
                Find out more <GoChevronRight />
              </a>
            </p>
          </div>
        </Row>
      </Container>
      <Container fluid={true} style={{ padding: "10px 15px" }}>
        <Row>
          <Navbar expand="lg" style={{ width: "100%" }}>
            <Link to="/" style={{ color: "#3452A4", textDecoration: "none" }}>
              <Navbar.Brand style={{ padding: 0 }}>
                <img src={data.logoPNG.publicURL} alt={"logo"} />
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={"justify-content-end"}
            >
              {data.allMenuMain &&
                data.allMenuMain.edges.map((edge, index) => {
                  if (!edge.node.below) {
                    return (
                      <Nav.Item key={index}>
                        <Link
                          to={edge.node.relative}
                          className="nav-link"
                          key={index}
                          activeClassName="active"
                        >
                          {edge.node.title}
                        </Link>
                      </Nav.Item>
                    )
                  } else {
                    return (
                      <>
                        <NavDropdown
                          className={"d-none d-lg-block"}
                          onFocus={() => {
                            onMouseEnter(index)
                          }}
                          onMouseOver={() => {
                            onMouseEnter(index)
                          }}
                          onMouseLeave={onMouseLeave}
                          show={dropdownOpen === index}
                          title={
                            <Link
                              to={edge.node.relative}
                              className="nav-link dropdown-item"
                              activeClassName="active"
                              style={{
                                backgroundColor: "transparent",
                                display: "initial",
                                padding: 0,
                              }}
                              key={index}
                            >
                              {edge.node.title}
                            </Link>
                          }
                        >
                          {edge.node.below.map((edge, index) => (
                            <Link
                              to={edge.relative}
                              className="nav-link dropdown-item"
                              activeClassName="active"
                              style={{ backgroundColor: "transparent" }}
                              key={index}
                            >
                              {edge.title}
                            </Link>
                          ))}
                        </NavDropdown>
                        <div className="d-lg-none">
                          <Nav.Item>
                            <Link
                              to={edge.node.relative}
                              className="nav-link nav-link"
                              activeClassName="active"
                              style={{ backgroundColor: "transparent" }}
                              key={index}
                            >
                              {edge.node.title}
                            </Link>
                          </Nav.Item>
                          <div style={{ padding: "0 15px" }}>
                            {edge.node.below.map((edge, index) => (
                              <Nav.Item key={index}>
                                <Link
                                  to={edge.relative}
                                  className="nav-link nav-link"
                                  activeClassName="active"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  {edge.title}
                                </Link>
                              </Nav.Item>
                            ))}
                          </div>
                        </div>
                      </>
                    )
                  }
                })}
            </Navbar.Collapse>
          </Navbar>
        </Row>
      </Container>
    </header>
  )
}
export default LayoutHeader

LayoutHeader.propTypes = {
  siteTitle: PropTypes.string,
}

LayoutHeader.defaultProps = {
  siteTitle: ``,
}
