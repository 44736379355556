import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import LayoutHeader from "./layoutHeader"
import LayoutFooter from "./layoutFooter"
import "./layout.style.base.scss"
import "./layout.style.theme.scss"
import "./layout.style.overrides.scss"

const Layout = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <LayoutHeader />
      {/*<header><h1>{data.site.siteMetadata.title}: LayoutHeader / SEO Title: {props.title}</h1></header>*/}
      <main {...props}>{props.children}</main>
      <LayoutFooter />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
