import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { Container, Row, Col, Carousel } from "react-bootstrap"

import "./carousel_ribbon.scss"

const ClientLogos = ({ data, type }) => {
  let edges = []
  switch (type) {
    case "Client Logos (Slider)":
      edges = data.allNodeClient.nodes
      break
    default:
      edges = []
  }

  return (
    <>
      <div className={"d-md-none clientlogo-carousel"}>
        <Items edges={edges} density={4} />
      </div>
      <div className={"d-none d-md-block d-xl-none"}>
        <Items edges={edges} density={4} />
      </div>
      <div className={"d-none d-xl-block"}>
        <Items edges={edges} density={5} />
      </div>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allNodeClient(
          filter: { field_featured: { eq: true } }
          sort: { fields: created, order: ASC }
        ) {
          nodes {
            id
            title
            relationships {
              field_icon {
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(height: 120, layout: FIXED)
                      }
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ClientLogos data={data} {...props} />}
  />
)

const Items = ({ edges, density }) => {
  let items = []
  for (let i in edges) {
    if (i % density === 0) items.push({ children: [edges[i]] })
    else items[items.length - 1].children.push(edges[i])
  }

  return (
    <Container style={{ paddingBottom: "30px" }}>
      <Carousel
        className="p-carousel-ribbon"
        prevIcon={
          <FaAngleLeft
            size={40}
            color={"#696969"}
            style={{
              left: "-55px",
              right: "auto",
              position: "absolute",
              top: "50%",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          />
        }
        nextIcon={
          <FaAngleRight
            size={40}
            color={"#696969"}
            style={{
              left: "auto",
              right: "-55px",
              position: "absolute",
              top: "50%",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          />
        }
        controls={true}
        interval={3000}
        indicators={false}
      >
        {items.map((item, key) => {
          return (
            <Carousel.Item key={key}>
              <Container>
                <Row className="justify-content-center">
                  {item.children.map((child, key) => {
                    return (
                      <Col
                        xs={Math.floor(12 / density)}
                        key={key}
                        className="item"
                      >
                        {child.relationships.field_icon.relationships
                          .field_media_image.localFile.childImageSharp && (
                          <GatsbyImage
                            image={
                              child.relationships.field_icon.relationships
                                .field_media_image.localFile.childImageSharp
                                .gatsbyImageData
                            }
                            imgStyle={{
                              objectFit: "contain",
                              maxWidth: "100%",
                            }}
                            alt={child.title}
                          />
                        )}
                        {!child.relationships.field_icon.relationships
                          .field_media_image.localFile.childImageSharp && (
                          <img
                            src={
                              child.relationships.field_icon.relationships
                                .field_media_image.localFile.publicURL
                            }
                            alt={child.title}
                          />
                        )}
                      </Col>
                    )
                  })}
                </Row>
              </Container>
            </Carousel.Item>
          )
        })}
      </Carousel>
    </Container>
  )
}
